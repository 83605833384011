import { computed, type ComputedRef, unref } from "vue";

import { __bucket } from "~/stores/bucket.store";
import { __identifier } from "~/stores/identifier.store";
import { __subscription } from "~/stores/subscription.store";

import type { Quota } from "~/types/drive";
import { Sub } from "~/types/subscriptions";

import type { SubObjectStorage } from "~/classes/subscriptions/SubscriptionObjectStorage";
import { getLabel, getType } from "~/composables/badges";
import { convertToToBytes, formatIntegerUnits, getSizeObject } from "~/composables/units";

export function useObjectStorageStats (objectStorage: SubObjectStorage): {
    rawObjectsAmount: ComputedRef<number>,
    objectsAmount: ComputedRef<Quota>,
    spaceTotal: ComputedRef<Quota | undefined>,
    spaceDisposed: ComputedRef<Quota>,
    spacePercent: ComputedRef<number | undefined>,
    isDisabled: ComputedRef<boolean>,
    isPastDue: ComputedRef<boolean>,
    badgeLabel: ComputedRef<string | undefined>,
    badgeType: ComputedRef<string | undefined>,
    amountBuckets: ComputedRef<number>,
    amountIdentifiers: ComputedRef<number>,
    isLoading: ComputedRef<boolean>,
    expiredAt: ComputedRef<number>
} {
    const rawObjectsAmount = computed<number>(() => unref(objectStorage.objectsAmount));
    const objectsAmount = computed<Quota>(() => getValueUnits(unref(objectStorage.objectsAmount)));

    const spaceTotal = computed<Quota | undefined>(() => {
        if (unref(objectStorage.isUnlimited) || !objectStorage.spaceTotal) return;
        return getSizeObject(convertToToBytes(unref(objectStorage.spaceTotal)));
    });
    const spaceDisposed = computed<Quota>(() => getSizeObject(unref(objectStorage.spaceDisposed)));

    const spacePercent = computed<number | undefined>(() => {
        const total = spaceTotal.value;
        const used = spaceDisposed.value;
        return total && used ? (used.quota / total.quota) * 100 : undefined;
    });

    const isDisabled = computed(() => unref(__subscription().getIsDisabled(Sub.Type.ObjectStorage)));
    const isPastDue = computed(() => objectStorage.status === Sub.Status.PastDue);

    const badgeLabel = computed(() => getLabel(isDisabled.value, isPastDue.value));
    const badgeType = computed(() => getType(isDisabled.value, isPastDue.value));

    const amountBuckets = computed(() => __bucket().getBucketsLength);
    const amountIdentifiers = computed(() => __identifier().getIdentifiersAmount);

    const isLoading = computed(() => __subscription().getIsLoading(Sub.Type.ObjectStorage));
    const expiredAt = computed(() => objectStorage.cancelAt ? objectStorage.cancelAt.getTime() : 0);

    function getValueUnits (baseValue: string | number): Quota {
        const newValue = formatIntegerUnits(parseFloat(`${baseValue}`));
        const [ quota, unit ] = newValue.split(" ");
        return { quota: parseFloat(quota), unit };
    }

    return {
        rawObjectsAmount,
        objectsAmount,
        spaceTotal,
        spaceDisposed,
        spacePercent,
        isDisabled,
        isPastDue,
        badgeLabel,
        badgeType,
        amountBuckets,
        amountIdentifiers,
        isLoading,
        expiredAt
    };
}
